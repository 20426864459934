<template>
    <div>
        <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
            <el-form-item label="工具名称:" prop="tName">
                <el-input style="width: 200px" v-model="ruleForm.tName"></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 35px">
                <el-button style="width: 100px" type="primary" @click="handleSave">确认</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { toolsManageEdit } from '@/common/api/tools-manage/toolsManage.js';
export default {
    name: 'toolsEdit',
    props: {
        toolsEditData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        var validateName = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('工具名称不能为空'))
            } else {
                callback();
            }
        }
        return {
            rules: {
                tName: [{ validator: validateName, trigger: 'blur' }],
            }
        }
    },
    computed: {
        ruleForm() {
            return this.toolsEditData;
        }
    },
    methods: {
        // 处理保存按钮
        handleSave() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let params = {
                        t_id: this.ruleForm.tId,
                        t_name: this.ruleForm.tName
                    }
                    toolsManageEdit(params).then(res => {
                        if (res.code === 200) {
                            this.$emit('close', false);
                        }
                    }).catch(err => {
                        // console.log(err);
                    })
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>

</style>